import {
  getProposalDescription,
  getProposalTitle,
  _getDescriptionException,
} from "proposal/helpers/description"
import type { AccountId, Proposal, ProposalMetadata } from "query/graphql"
import { useDescriptionExceptions } from "proposal/hooks/useDescriptionExceptions"

type Values = {
  title: string
  description: string
  quorum?: string
  disabled: boolean
}

type Props = {
  proposal: Pick<Proposal, "id" | "onchainId"> & {
    metadata: Pick<ProposalMetadata, "description">
  }
  governanceId: AccountId
}

export const useDescriptionException = ({
  proposal,
  governanceId,
}: Props): Values => {
  const { descriptionExceptions } = useDescriptionExceptions()

  const { descriptionException } = _getDescriptionException({
    descriptionExceptions,
    governanceId,
    proposalId: proposal.id,
    proposalOnchainId: proposal.onchainId ?? "",
  })

  const NAOUNS_DAO_ACCOUNT_ID =
    "eip155:1:0xE087F94c3081e1832dC7a22B48c6f2b5fAaE579B"
  const hasNounsException =
    governanceId === NAOUNS_DAO_ACCOUNT_ID && Number(proposal.id) > 6

  if (hasNounsException) {
    const normalizeDescription = (description: string) => {
      const LINE_BREAK_REGEX = /\\n/gi

      return description.replace(LINE_BREAK_REGEX, "\n")
    }

    const description = normalizeDescription(proposal.metadata.description)
    const title = getProposalTitle(description)
    const quorum = descriptionException?.quorum
    const disabled = descriptionException?.disabled ?? false

    return {
      title,
      description,
      quorum,
      disabled,
    }
  }

  const title = descriptionException?.description
    ? getProposalTitle(descriptionException.description)
    : getProposalTitle(proposal.metadata.description)

  const description = descriptionException?.description
    ? getProposalDescription(descriptionException.description)
    : getProposalDescription(proposal.metadata.description)

  const quorum = descriptionException?.quorum
  const disabled = descriptionException?.disabled ?? false

  return {
    title,
    description,
    quorum,
    disabled,
  }
}
