import type { FC } from "react"
import React from "react"
import { Flex, Text, Stack, Tooltip, Icon, HStack } from "@chakra-ui/react"

import ProposalGovernance from "common/components/columns/ProposalGovernance"
import Link from "common/components/Link"
import type {
  Proposal,
  Governor,
  Vote,
  VoteStats,
  Maybe,
  ProposalMetadata,
  OrganizationMetadata,
} from "query/graphql"
import { ProposalStatus } from "query/graphql"
import ProposalStatusTag from "proposal/components/ProposalStatusTag"
import { CircleInfo } from "ui/components/icons/font-awesome/CircleInfo"
import { DATE_FORMATS } from "common/constants/date"
import { getDateFromTimestamp, getTimestampFromIetf } from "common/helpers/date"
import ProposalVotesUser from "common/components/columns/ProposalVotesUser"
import { useDescriptionException } from "proposal/hooks/useDescriptionException"
import { getOrganizationIcon } from "organization/helpers/organization"
import GovernanceAvatar from "governance/components/GovernanceAvatar"

type ProposalIdentityProps = {
  isSignalProposal?: boolean
  organization: {
    metadata?: Maybe<Pick<OrganizationMetadata, "icon">>
  }
  proposal: Pick<Proposal, "id" | "onchainId" | "status" | "createdAt"> & {
    metadata: Pick<ProposalMetadata, "description" | "title">
    governor?: Maybe<Pick<Governor, "id" | "name" | "timelockId" | "type">>
  }
  decimals: number

  voterVote?: Vote
  displayGovernorName?: boolean

  quorum: string
  voteStats?: VoteStats[]
  href: string
}

function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text
  }

  return text.slice(0, maxLength) + "..."
}

const getCreatedDateLabel = (
  proposal: Pick<Proposal, "createdAt" | "block">,
) => {
  const { createdAt, block } = proposal

  const dateToShow = block?.timestamp || createdAt
  const timestamp = getTimestampFromIetf(dateToShow)
  const date = getDateFromTimestamp(timestamp)
  const createdDateLabel = date.format(DATE_FORMATS.monthThYear)

  return createdDateLabel
}

const ProposalIdentity: FC<ProposalIdentityProps> = ({
  proposal,
  decimals,
  displayGovernorName = false,
  voterVote,
  quorum,
  voteStats,
  href,
  isSignalProposal = false,
}) => {
  const { title, disabled } = useDescriptionException({
    governanceId: proposal.governor?.id ?? "",
    proposal,
  })

  const createdDateLabel = getCreatedDateLabel(proposal)

  return (
    <HStack align="center" minW="300px" spacing={4}>
      <Flex direction="column" maxW="lg">
        <HStack align="center" spacing={2}>
          <Link href={href}>
            <Text
              color={disabled ? "gray.400" : ""}
              data-qa="proposals-name"
              fontWeight="bold"
              mb={1}
              noOfLines={1}
              textStyle="md"
            >
              {truncateText(
                isSignalProposal ? proposal.metadata.title : title,
                60,
              )}
            </Text>
          </Link>
          {proposal.status === ProposalStatus.Succeeded ? (
            <Tooltip
              label="This proposal needs to be queued"
              placement="top-end"
            >
              <Icon as={CircleInfo} h={3} w={3} />
            </Tooltip>
          ) : null}
          {proposal.status === ProposalStatus.Queued ||
          proposal.status === ProposalStatus.Pendingexecution ? (
            <Tooltip
              label="This proposal needs to be executed"
              placement="top-end"
            >
              <Icon as={CircleInfo} h={3} w={3} />
            </Tooltip>
          ) : null}
        </HStack>

        <Stack direction="column" spacing={1}>
          {!disabled && proposal.status ? (
            <Flex align="center" data-qa="proposals-status" direction="row">
              <ProposalStatusTag
                decimals={decimals}
                governor={proposal.governor}
                isSignalProposal={isSignalProposal}
                quorum={quorum}
                size="sm"
                status={proposal.status}
                timelockId={proposal.governor?.timelockId}
                voteStats={voteStats}
              />
              <Text color="gray.500" ml={2} textStyle="body.regular.md">
                {createdDateLabel}
              </Text>
            </Flex>
          ) : null}

          {voterVote ? (
            <Flex align="center" direction="row">
              <ProposalVotesUser
                key={`governance-proposals-user-vote-${proposal.id}`}
                voterVote={voterVote}
              />
            </Flex>
          ) : null}

          {displayGovernorName && proposal.governor ? (
            <Flex align="center" direction="row">
              <ProposalGovernance governance={proposal.governor} />
            </Flex>
          ) : null}
        </Stack>
      </Flex>
    </HStack>
  )
}

const ProposalIdentityMobile: FC<ProposalIdentityProps> = ({
  organization,
  proposal,
  decimals,
  voterVote,
  href,
  quorum,
  voteStats,
  isSignalProposal = false,
}) => {
  const icon = getOrganizationIcon(organization)

  const { title, disabled } = useDescriptionException({
    governanceId: proposal.governor?.id ?? "",
    proposal,
  })

  return (
    <>
      <HStack align="center" spacing={4}>
        <GovernanceAvatar size={8} src={icon} />
        <Flex direction="column" maxW="lg">
          <HStack align="center" spacing={2}>
            <Link href={href}>
              <Text
                as="h6"
                color={disabled ? "gray.400" : ""}
                mb={1}
                noOfLines={1}
                textStyle="h6"
              >
                {truncateText(
                  isSignalProposal ? proposal.metadata.title : title,
                  30,
                )}
              </Text>
            </Link>
            {proposal.status === ProposalStatus.Succeeded ? (
              <Tooltip
                label="This proposal needs to be queued"
                placement="top-end"
              >
                <Icon as={CircleInfo} h={3} w={3} />
              </Tooltip>
            ) : null}
            {proposal.status === ProposalStatus.Queued ? (
              <Tooltip
                label="This proposal needs to be executed"
                placement="top-end"
              >
                <Icon as={CircleInfo} h={3} w={3} />
              </Tooltip>
            ) : null}
          </HStack>
          <HStack align="center" spacing={1}>
            {proposal.status ? (
              <ProposalStatusTag
                decimals={decimals}
                governor={proposal.governor}
                isSignalProposal={isSignalProposal}
                quorum={quorum}
                size="sm"
                status={proposal.status}
                timelockId={proposal.governor?.timelockId}
                voteStats={voteStats}
              />
            ) : null}
          </HStack>
        </Flex>
      </HStack>
      {voterVote ? (
        <Flex align="center" direction="row">
          <ProposalVotesUser
            key={`governance-proposals-user-vote-${proposal.id}`}
            voterVote={voterVote}
          />
        </Flex>
      ) : null}
    </>
  )
}

export { ProposalIdentity, ProposalIdentityMobile }
